import {Link, Route} from 'react-router-dom';

import {TraCuu} from './tra-cuu';

export const App = () => {
	return (
			<div className="App">
				<div className="layout-topbar">
					<div className="flex align-items-center justify-content-between">
						<Link to="/" className="layout-topbar-logo">
							<img src={'/assets/layout/images/logo.png'} alt="logo"/>
						</Link>
					</div>
				</div>

				<div>
					<Route path="/" exact component={TraCuu}/>
				</div>

				<div className="layout-footer text-center text-white p-5">
					<b>CÔNG TY CỔ PHẦN iCORP</b>
					<p><i className="pi pi-map-marker mr-2"/>Địa chỉ: Số 32/21 Phố Trương Công Giai, Phường Dịch Vọng, Quận Cầu Giấy, Thành phố Hà Nội.</p>
					<p><i className="pi pi-map-marker mr-2"/>VPGD: Tầng 6, Số 82 Trần Thái Tông, Dịch Vọng Hậu, Cầu Giấy, Hà Nội</p>
					<p><i className="pi pi-phone mr-2"/><b>Hotline:</b> 1900.0099</p>
					<p><i className="pi pi-envelope mr-2"/><b>Email:</b> vietinvoice@icorp.vn</p>
				</div>
			</div>
	);
}
